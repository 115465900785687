import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Checkbox, FormControlLabel } from '@mui/material/';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
const columns = [
    { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    //   { field: "date", name: "تاريخ الاضافة ", options: { filter: true, sort: false, } },
    { field: "add_date", name: "التاريخ ", options: { filter: true, sort: false, } },

    { field: "amount", name: "المبلغ ", options: { filter: true, sort: false, } },
    { field: "name", name: "اسم المصروف", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: true,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: ''
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/spending/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.Orders();
            })
            .catch(error => { console.log(error) })
    }
    Orders() {
        var date1 = moment(document.getElementById('date1').value, 'DD/MM/YYYY').format('MM/DD/YYYY');
        var date2 = moment(document.getElementById('date2').value, 'DD/MM/YYYY').format('MM/DD/YYYY');
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/spending/my?from=${date1}&to=${date2}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data);
                for (let i = 0; i < res.data.data.spending.length; i++) {
                    let obj = {
                        id: res.data.data.spending[i].id,
                        name: res.data.data.spending[i].name,
                        amount: res.data.data.spending[i].amount,
                        add_date: moment(res.data.data.spending[i].add_at).tz('Asia/baghdad').format('YYYY/MM/DD LT'),

                        date: moment(res.data.data.spending[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT'),

                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف مصروف "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.DeleteCate(res.data.data.spending[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ cursor: 'pointer', color: '#ec7373' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,

                    };
                    arr.push(obj);
                }
                this.setState({
                    orders: arr, spin: false, total: res.data.data.amount
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Component initialState={{ isShown: false, nameُExpenses: '', amountExpenses: '', dateExpenses: '' }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة مصروف</div>
                                                        <div id='inputAdd2Continer' >

                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم المصروف</label>
                                                                <input autoComplete='off' type='text' placeholder='اسم المصروف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ nameُExpenses: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>المبلغ</label>
                                                                <input autoComplete='off' type='number' step="0.01" placeholder='المبلغ' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ amountExpenses: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>التاريخ</label>

                                                                <input autoComplete='off' type='date' placeholder='التاريخ' id='dateEx'
                                                                    onChange={(e) => setState({ dateExpenses: e.target.value })} />
                                                            </div>

                                                            <center >
                                                                <FormControlLabel
                                                                    label="اضافة كـ ترويج"
                                                                    control={
                                                                        <Checkbox
                                                                            id="is_ad"
                                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}

                                                                        />
                                                                    }
                                                                />
                                                            </center>
                                                            <div >

                                                            </div>
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                let check = 0;
                                                                if (document.getElementById('is_ad').checked) {
                                                                    check = 1;

                                                                }
                                                                axios.post(host + `dashbord/spending/add`,
                                                                    qs.stringify({
                                                                        name: state.nameُExpenses,
                                                                        price: state.amountExpenses,
                                                                        date: state.dateExpenses,
                                                                        check
                                                                    }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        toast('تمت الاضافة بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });

                                                                    })
                                                                    .catch(error => {
                                                                        toast.error("حصل خطأ تأكد من المعلومات", {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        console.log(error)
                                                                        this.setState({ spinerBtn: false })
                                                                    })
                                                                setState({ isShown: false })

                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة مصروف</div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>

                                <div id='ContinerReportDate'>
                                    <Row id='RowReportDate'>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <span>من : &nbsp;</span>
                                            <DatePicker id='date1'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.from}
                                                onChange={this.handleChangeFrom}
                                            />
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <span>الى : &nbsp;</span>
                                            <DatePicker id='date2'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.to}
                                                onChange={this.handleChangeTo}
                                            />
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })
                                                this.Orders()
                                            }}>بحث</div>
                                        </Col>
                                    </Row>
                                </div>
                                {!this.state.serch ? null : (
                                    <div>
                                        <div className='DataTableContiner'>
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                            </MuiThemeProvider>
                                        </div>
                                        <div id='ContinerReportDate1'>
                                            <div id='AddClientDeptBTN1'>المجموع</div>
                                            <div style={{ marginRight: 20 }}>{this.state.total}</div>
                                        </div>
                                    </div>
                                )}
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;