import React from 'react';
import ScrollToTop from './assets/js/scrolToTop';
import Context from './assets/js/Context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import NotFound from './assets/js/NotFound';
import SideBar from './component/common/sidebar';
import Cookies from "universal-cookie";
import host from './assets/js/Host';
import axios from 'axios';
import LoginDash from './component/common/login';
import getorderbyid from './component/dash/getOrderById';

import Wasil from './component/dash/wasil';
import Admins from './component/dash/admins';

import Page from './component/dash/pageById';
import GeneralSetting from './component/dash/GeneralSetting';

import UserById from './component/dash/userById';
import Edit from './component/dash/edit';
import Dayle_orders from './component/dash/dayle_orders';
import CustomerOrder from './component/dash/customerOrder';
import Items2Dash from './component/dash/items2';
import Item2 from './component/web/item2'
import Side from './component/web/sidebar2';
import CustomerOrderUser from './component/web/customerOrder';

import Employee from './component/dash/employee';
import Pages from './component/dash/pages';

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/login.css';
import './assets/css/sidebar.css';
import './App.css';
const cookies = new Cookies();

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      shopNum: '',
    }
  }
  componentDidMount() {
    if (!cookies.get('tokenAdmin')) {
      var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
      axios.get(host + `users/cart/`, { headers: header })
        .then(res => {

          this.setState({ shopNum: res.data.data.cart_items.item.length });
        })
        .catch(error => { console.log(error) })
    }
  }
  render() {
    return (
      <div >
        <Context.Provider value={{
          value: this.state, action: {
            RefreshComponent: () => {
              this.componentDidMount()
            },
          }
        }}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/' component={LoginDash} />
                  <Route path='/loginDash' component={LoginDash} />
                  <Route path='/Expenses' component={SideBar} />
                  <Route path='/fromorders' component={SideBar} />
                  <Route path='/Search' component={SideBar} />
                  <Route path='/fromordersReports' component={SideBar} />
                  <Route path='/fromordersWatting' component={SideBar} />
                  <Route path='/getorderbyid' component={getorderbyid} />
                  <Route path='/Report' component={SideBar} />
                  <Route path='/delevryReports' component={SideBar} />

                  <Route path='/SettingDash' component={SideBar} />
                  <Route path='/Cate2' component={SideBar} />
                  <Route path='/DoneOrder' component={SideBar} />
                  <Route path='/RejectOrder' component={SideBar} />
                  <Route path='/selles' component={SideBar} />
                  <Route path='/doneselles' component={SideBar} />
                  <Route path='/wattingselles' component={SideBar} />
                  <Route path='/apadningOrders' component={SideBar} />
                  <Route path='/cancelledOrders' component={SideBar} />
                  <Route path='/calledOrders' component={SideBar} />
                  

                  
                  <Route path='/printlist' component={SideBar} />
                  <Route path='/dayle_orders' component={Dayle_orders} />

                  <Route path='/sheet' component={SideBar} />
                  
                  <Route path='/miss' component={SideBar} />
                  <Route path='/check' component={SideBar} />
                  <Route path='/print' component={SideBar} />
            

                  <Route path='/wasil' component={Wasil} />

                  <Route path='/user' component={UserById} />
                  

                  <Route path='/Category2' component={Side} />
                  <Route path='/Shop2' component={Side} />
                  <Route path='/AllProdect' component={Side} />
                  <Route path='/Orders' component={Side} />
                  <Route path='/Customers' component={Side} />
                  <Route path='/CustomerOrder/:id' component={CustomerOrderUser} />
                  <Route path='/Edit/:id' component={Edit} />
                  <Route path='/Item2/:id' component={Item2} />
                  <Route path='/CustomerOrderDash/:id' component={CustomerOrder} />
                  <Route path='/Items2Dash/:id' component={Items2Dash} />

                  <Route path='/se' component={Items2Dash} />
                  <Route path='/Employee' component={Employee} />
                  <Route path='/pages' component={Pages} />
                  <Route path='/page' component={Page} />
                  <Route path='/admins' component={Admins} />

                  {/* <Route path='/page' component={Page} /> */}

                  <Route path='/GeneralSetting' component={GeneralSetting} />

                  
                  {/* <Route  exact={true} path='*' component={NotFound} /> */}
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </Context.Provider>

      </div>
    );
  }
}

export default App;
