import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, Icon } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ImageIcon from '@material-ui/icons/Image';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from '../common/login';
const columns = [
    { field: "delete", name: "حذف", options: { width: 100, filter: true, sort: false, } },
    { field: "edit", name: "تعديل", options: { width: 100, filter: true, sort: false, } },
    { field: "photo", name: "صورة", options: { width: 100, filter: true, sort: false, } },
    { field: "editCount", name: "اضافة عدد", options: { width: 100, filter: true, sort: false, } },
    { field: "count", name: "العدد", options: { filter: true, sort: false, } },
    { field: "barcode", name: "الباركود", options: { filter: true, sort: false, } },
    { field: "price", name: "سعر البيع", options: { filter: true, sort: false, } },
    { field: "dwprice", name: "سعر الشراء", options: { filter: true, sort: false, } },
    { field: "name", name: "اسم المادة", options: { filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    rowsPerPage: 100,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            CountItem: '',
            nameItem: '',
            priceItem: '',
            dw_price: '',
            barcode: 0,
            image: [],
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/component/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.itmes);
                for (let i = 0; i < res.data.data.itmes.length; i++) {
                    let obj = {
                        name: res.data.data.itmes[i].name,
                        dwprice: res.data.data.itmes[i].dw_price,
                        price: res.data.data.itmes[i].price,
                        count: res.data.data.itmes[i].count,
                        barcode: res.data.data.itmes[i].barcode,
                        editCount: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='اضافة عدد'
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="اضافة"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            // console.log(state.img);
                                            this.EditCount(res.data.data.itmes[i].id)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <div style={{ direction: 'rtl' }}>
                                            <div id={'ContinerInPut'} >
                                                <label>العدد</label>
                                                <input autoComplete='off' type='number' placeholder='العدد ' id='InputTExtDash1'
                                                    onChange={(e) => this.setState({ totalCount: e.target.value })} />
                                            </div>

                                        </div>
                                    </Dialog>
                                    <PlusOneIcon style={{ cursor: 'pointer', color: '#516fdc' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        photo: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasHeader={false}
                                        hasFooter={false}
                                    >
                                        <div>
                                            <img src={host + 'files/' + res.data.data.itmes[i].photo} alt='img' id='imageItemDash' />

                                        </div>
                                    </Dialog>
                                    <ImageIcon style={{ cursor: 'pointer', color: '#08aba3' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit: <EditIcon 
                        onClick={()=>{
                            window.open(`/Edit/${res.data.data.itmes[i].id}`)
                            localStorage.setItem('nameIT',res.data.data.itmes[i].name)
                            localStorage.setItem('SpriceIT',res.data.data.itmes[i].dw_price)
                            localStorage.setItem('PpriceIT',res.data.data.itmes[i].price)
                            localStorage.setItem('BarcodeIT',res.data.data.itmes[i].barcode)
                            localStorage.setItem('category_id',this.props.match.params.id)
                        }}
                        style={{ cursor: 'pointer', color: '#4752d1' }} /> ,
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف قسم "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.DeleteCate(res.data.data.itmes[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ cursor: 'pointer', color: '#ec7373' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    Category: arr, spin: true
                });
            })
            .catch(error => { console.log(error) })

    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/component/${id}?`, { headers: header })
            .then(response => {

                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    EditCount(id) {
        axios.put(host + `dashbord/components/count/${id}`,
            qs.stringify({ count: this.state.totalCount }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.componentDidMount();
                toast('تم اضافة العدد بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => {
                console.log(error.response.data.message)
                this.setState({ spinerBtn: false })
            })
    }
    AddItem() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameItem);
        formData.append("count", this.state.CountItem);
        formData.append("price", this.state.priceItem);
        formData.append("dw_price", this.state.dw_price);
        formData.append("barcode", this.state.barcode);
        formData.append("hasCount", true);
        formData.append("category_id", this.props.match.params.id);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/component/add/`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ spinerBtn: false })
                this.componentDidMount()

                toast('تم اضافة المادة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Link to='/Cate2'>
                                        <Icon icon="circle-arrow-left" size={30} color="#fff"
                                            style={{ cursor: 'pointer' }} />
                                    </Link>
                                    <Component initialState={{ isShown: false }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40} style={{ direction: 'rtl' }}>
                                                        <div id='titleAddAppBar'>اضافة مادة</div>
                                                        <div id='inputAddContiner'>
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم المادة </label>
                                                                <input autoComplete='off' type='text' placeholder='اسم المادة ' id='InputTExtDash1'
                                                                    onChange={(e) => this.setState({ nameItem: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>سعر الشراء </label>
                                                                <input autoComplete='off' type='number' placeholder='سعر الشراء ' id='InputTExtDash1'
                                                                    onChange={(e) => this.setState({ dw_price: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>سعر البيع </label>
                                                                <input autoComplete='off' type='number' placeholder='سعر البيع ' id='InputTExtDash1'
                                                                    onChange={(e) => this.setState({ priceItem: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>الباركود</label>
                                                                <input autoComplete='off' type='number' placeholder='الباركود' id='InputTExtDash1'
                                                                    onChange={(e) => this.setState({ barcode: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>العدد </label>
                                                                <input autoComplete='off' type='number' placeholder='العدد ' id='InputTExtDash1'
                                                                    onChange={(e) => this.setState({ CountItem: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'}>
                                                                <label>اختر صورة</label>
                                                                <input type='file'
                                                                    id='InputTExtDash111Image'
                                                                    onChange={file => {
                                                                        console.log(file.target.files);
                                                                        this.setState({ image: file.target.files })
                                                                    }}
                                                                    placeholder="Select the file "
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id='btnAddItemContiner'>
                                                            {!this.state.spinerBtn ? (
                                                                <div id='btnAddItem' onClick={() => {
                                                                    this.setState({ spinerBtn: true })
                                                                    this.AddItem()
                                                                    setState({ isShown: false })
                                                                }}>اضافه</div>

                                                            ) : (
                                                                <div id='btnAddItem'><Spinner size={24} /></div>

                                                            )}


                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة مادة</div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: 'calc(100vh)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner/>
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;